import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputRef,
  message,
  Modal,
  ModalProps,
  Row,
  Select,
  Skeleton,
  Spin,
  Tooltip
} from 'antd';
import {
  deleteProductGroup,
  fetchLinkedProducts,
  insertGroupedProducts,
  searchProducts,
  updateGroupedProducts
} from 'sb/api/products';
import { ProductSearchResultsType } from 'sb/models/Product';
import { ProductComp } from 'sb/models/ProductComp';
import { LinkedProductsTable } from 'components/atoms/LinkedProductsTable';
import { DoubleLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash-es';
import { RefSelectProps } from 'antd/es/select';
import { LinkedProduct } from 'components/molecules/LinkedProducts';
import './ProductLinkingModal.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormatCurrency } from 'sb/shared/Helpers';
import { ACLCheck } from 'shared/Helpers';

interface ProductLinkageForm {
  name: string;
  productSelection: number[];
}

interface Props extends ModalProps {
  productId?: number;
}

export const ProductLinkingModal: React.FC<Props> = ({ productId, onOk, ...rest }) => {
  const [form] = Form.useForm<ProductLinkageForm>();
  const selectRef = useRef<RefSelectProps>(null);
  const nameRef = useRef<InputRef>(null);

  // LOCAL STATE
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [linkedProducts, setlinkedProducts] = useState<LinkedProduct[]>([]);
  const [searchOptions, setSearchOptions] = useState<ProductComp[]>([]);
  const [searchStr, setSearchStr] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
  const [product, setProduct] = useState<undefined | LinkedProduct>(undefined);
  const [limitToSupplier, setLimitToSupplier] = useState(true);

  useEffect(() => {
    if (!productId) return;
    setSelectedIds([]);
    setlinkedProducts([]);
    setSearchOptions([]);
    setLoading(true);
    setSearchStr('');
    form.resetFields();

    fetchLinkedProducts(productId)
      .then((products: LinkedProduct[]) => {
        setlinkedProducts(products);
        const [product] = products.filter((prod: LinkedProduct) => prod.id === productId);
        setProduct(product);
        if (product.product_group_product.length > 0) {
          form.setFieldValue('name', product.product_group_product[0].product_group.name);
        }
        const selectedIds = products.map((prod: LinkedProduct) => prod.id);
        setSelectedIds(selectedIds);
      })
      .catch(() => message.error('Problem retrieving linked products'))
      .finally(() => {
        setLoading(false);
      });
  }, [form, productId]);

  useEffect(() => {
    if (!loading) nameRef.current?.focus();
  }, [loading]);

  const handleOk = async (e: React.MouseEvent<HTMLElement>) => {
    if (!product) return;

    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const name = await form.getFieldValue('name');
    try {
      setUpdating(true);
      if (product.product_group_product.length > 0) {
        if (linkedProducts.length >= 2) {
          await updateGroupedProducts(
            product.product_group_product[0].product_group_id,
            linkedProducts.map((item: LinkedProduct) => item.id),
            name
          );
        } else {
          await deleteProductGroup(product.product_group_product[0].product_group_id);
        }
      } else {
        await insertGroupedProducts(
          linkedProducts.map((item: LinkedProduct) => item.id),
          name
        );
      }

      onOk?.(e);
    } finally {
      setUpdating(false);
    }
  };

  const handleSearch = async (e: any) => {
    if (searchStr.length <= 2) return;

    if (e.code === 'Enter') {
      await productSearch();
    }
  };

  const productSearch = async () => {
    setSearching(true);
    selectRef.current?.blur();
    const products = await searchProducts(searchStr, undefined, limitToSupplier ? product?.vendor.id : undefined);
    if (products.length > 0) {
      const linkedProductsIds = linkedProducts.map((item: LinkedProduct) => item.id);
      setSearchOptions(products.filter((item: ProductSearchResultsType) => linkedProductsIds.indexOf(item.id) === -1));
      setSearchOpen(true);
    }
    setSearching(false);
  };

  const handleSelect = (id: string) => {
    const _selectedProductIds = cloneDeep(selectedIds);
    _selectedProductIds.push(parseInt(id));
    setSelectedIds(_selectedProductIds);
  };

  const handleDeselect = (id: string) => {
    const _selectedProductIds = cloneDeep(selectedIds);
    setSelectedIds(_selectedProductIds.filter((item: number) => item !== parseInt(id)));
  };

  const searchProductOptions = searchOptions.map((i) => (
    <Select.Option key={i.id}>
      {
        <div style={{ fontSize: 13 }}>
          {i.vendor.name}: <strong style={{ color: '#000' }}>{i.name}</strong> ({i.code} /{' '}
          {FormatCurrency(i.avg_unit_cost)})
        </div>
      }
    </Select.Option>
  ));

  const handleAddSelectedProductsClick = () => {
    const filteredProducts: ProductComp[] = searchOptions.filter((item) => selectedIds.indexOf(item.id) > -1);
    const newFilteredProducts = filteredProducts.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code,
        vendor: { id: item.vendor.id, name: item.vendor.name },
        product_group_product: []
      };
    });
    setlinkedProducts([...newFilteredProducts, ...linkedProducts]);
    setSelectedIds([]);
    setSearchOptions([]);
    form.setFieldValue('productSelection', undefined);
  };

  const handleOnRemoveProduct = (rec: LinkedProduct) => {
    setlinkedProducts(linkedProducts.filter((item: LinkedProduct) => item.id !== rec.id));
  };

  const handleLimitSearchChange = (e: CheckboxChangeEvent) => {
    setLimitToSupplier(e.target.checked);
  };

  return (
    <Modal
      className="product-linking-modal"
      title={
        <div>
          {product?.vendor.name}: <strong style={{ color: '#000' }}>{product?.name}</strong> ({product?.code})
        </div>
      }
      {...rest}
      confirmLoading={updating}
      open={!!productId}
      forceRender
      cancelText={ACLCheck(['update:contract_all']) ? 'Cancel' : 'Close'}
      okText={
        product && product?.product_group_product.length > 0 && linkedProducts.length < 2
          ? 'Ungroup Products!'
          : 'Group Products'
      }
      okButtonProps={{
        className: product && product?.product_group_product.length > 0 && linkedProducts.length < 2 ? 'warning' : '',
        disabled: product && product?.product_group_product.length === 0 && linkedProducts.length < 1,
        style: { display: ACLCheck(['update:contract_all']) ? 'inline-block' : 'none' }
      }}
      destroyOnClose
      width={1200}
      onOk={handleOk}
    >
      <Skeleton active loading={loading}>
        {ACLCheck(['update:contract_all']) && (
          <>
            <Spin spinning={searching}>
              <Form<ProductLinkageForm> layout="vertical" form={form} size="middle" colon={false}>
                <Form.Item
                  label="Product Grouping Name"
                  name="name"
                  rules={[{ required: true, message: 'Enter a name for this product grouping' }]}
                >
                  <Input
                    maxLength={48}
                    showCount
                    ref={nameRef}
                    placeholder="Product grouping name"
                    style={{ width: '100%' }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Row justify={'space-between'}>
                      <Col>Product Search</Col>
                      <Col>
                        <Checkbox
                          checked={limitToSupplier}
                          onChange={handleLimitSearchChange}
                        >{`Limit search to ${product?.vendor.name}`}</Checkbox>
                      </Col>
                    </Row>
                  }
                  name="productSelection"
                >
                  <Select
                    open={searchOpen}
                    ref={selectRef}
                    mode="multiple"
                    placeholder="Search for products to link to this one"
                    size="large"
                    style={{ width: '95%' }}
                    virtual={true}
                    filterOption={true}
                    onBlur={() => setSearchOpen(false)}
                    onFocus={() => setSearchOpen(true)}
                    onSearch={setSearchStr}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    onInputKeyDown={handleSearch}
                    notFoundContent={null}
                  >
                    {searchProductOptions}
                  </Select>
                  <Button
                    disabled={searchStr.length <= 2}
                    className="search-btn"
                    size="large"
                    onClick={productSearch}
                    type="primary"
                    icon={<SearchOutlined />}
                  />
                </Form.Item>
              </Form>
            </Spin>
            <div style={{ textAlign: 'center', marginTop: -8, marginBottom: 12 }}>
              <Tooltip title="Add selected products to Linked Products table before pressing OK">
                <Button
                  onClick={handleAddSelectedProductsClick}
                  disabled={selectedIds.length === 0}
                  size="large"
                  icon={<DoubleLeftOutlined rotate={-90} />}
                >
                  Add
                </Button>
              </Tooltip>
            </div>
          </>
        )}
        <LinkedProductsTable productId={productId} products={linkedProducts} onProductRemove={handleOnRemoveProduct} />
      </Skeleton>
    </Modal>
  );
};
